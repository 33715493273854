.image-profile {
  width: 120px;
  height: 120px;
  color: grey;
}

.infoProfile {
  background-color: #f0f3f4;
  border-radius: 9px;
}
