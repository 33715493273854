.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5em;
  background-color: #007ea2;
  color: #fff;
  height: 12vh;
}

.logo {
  width: 115px;
  height: 80px;
  object-fit: cover;
}

.buttonVolume {
  border-color: white;
  background-color: red;
  width: 20;
  border-radius: 10;
}

.iconVolume {
  color: white;
}

.buttonHelp {
  background-color: white;
  border-radius: 10;
  height: 40;
}

.textHelp {
  color: #007ea2;
}
